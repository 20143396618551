//Services
import cartService from "../../_foundation/apis/transaction/cart.service";
import { localStorageUtil } from "../utils/storageUtil";
declare var _satellite: any;
 
const adobeService = {
  async track(parameters: any) {
    var viewPort: any;
    if (/android|iphone/i.test(navigator.userAgent)) {
      viewPort = "mobile";
    } else if (/Tablet|ipad/i.test(navigator.userAgent)) {
      viewPort = "tablet";
    } else {
      viewPort = "pc";
    }

    let currentUser: any = localStorage.getItem("HCS-FordChargers-currentUser");
    const currentUserObj: any = JSON.parse(currentUser);

    var digitaldata: any = {};
    digitaldata.page = {
      pageName: "fcg:" + parameters?.pageName,
      siteSection: parameters?.siteSection,
      hierarchy: "fcg:" + parameters?.hierarchy,
      userLanguage: "eng",
      pageNameNoVehicle: "fcg:" + parameters?.pageName,
      client: "ford",
      site: "chargers.ford.com",
      radUIVersion: "ui:rad:" + viewPort,
      variantName: parameters?.variantName,
      tool: parameters?.tool,
    };
    digitaldata.user = {
      loginStatus:
        currentUserObj && currentUserObj?.userLoggedIn
          ? "logged in"
          : "logged out",
      registeredStatus:
        currentUserObj && !currentUserObj?.isGuest
          ? "registered"
          : "non-registered",
    };

     
    if (parameters?.eventType === "globalOnclick") {
      digitaldata.onclick = {
        onclickLinkName: parameters?.onClickLinkName,
        onclick: parameters?.onClickName,
      };
    } else if (parameters?.eventType === "globalOnclickDiscountApply") {
      digitaldata.onclick = {
        onclickLinkName: parameters?.onClickLinkName,
        onclick: parameters?.onClickName,
      };
      digitaldata.cart = {
        discountCode: parameters?.promoCode,
        discountAmount: parameters?.totalDiscounts,
      };
    } else if (parameters?.eventType === "cartCheckout") {
      const cartInfo = await cartService.getCart({});
      const getCartItems = localStorageUtil.get('cartItems');
      const cartProductInfo =
        cartInfo?.data?.orderItem?.length > 0 ? cartInfo?.data?.orderItem : [];
       

      //FPR Amount calculation
      let fordRedeemPointsUsedVal = '';
      if (parameters?.FPR_Enabled) {
        const fordRedeemPointsUsedArray = cartInfo?.data?.adjustment?.length>0? (cartInfo.data.adjustment.filter((f)=>f.code.includes("FPR"))):0;
        const fordRedeemPointsUsed = fordRedeemPointsUsedArray[0]?.amount.slice(1);
        fordRedeemPointsUsedVal = typeof(fordRedeemPointsUsed) === "undefined" ? 0 : fordRedeemPointsUsed;
      }

      digitaldata.cart = {
        step: parameters?.step,
        orderNumber: "no-order-number",
        subTotalNoDiscount: parseFloat(
          cartInfo?.data?.totalProductPrice
        ).toFixed(2),
        subTotal: parseFloat(cartInfo?.data?.totalProductPrice).toFixed(2),
        shippingCost: parseFloat(cartInfo?.data?.totalShippingCharge).toFixed(
          2
        ),
        taxes: parseFloat(cartInfo?.data?.totalSalesTax).toFixed(2),
        cartTotal: parseFloat(cartInfo?.data?.grandTotal).toFixed(2),
        deliveryMethod: "free shipping",
        fpRewards: fordRedeemPointsUsedVal,
        discountCode: (cartInfo?.data?.promotionCode && cartInfo?.data?.promotionCode?.length > 0)?cartInfo?.data?.promotionCode?.map((discountVal: any) => ({ discountCode: discountVal?.code })): 'no-discount-code',
        
        discountAmount: parseFloat(
          cartInfo?.data?.totalAdjustment?.substring(1)
        ).toFixed(2),
        productInfo: cartProductInfo.map((val: any, index:number) => {
          var promoCodesProdArr: any = [];
          var promoAmountProdArr: any = 0;
          if (val?.adjustment?.length > 0) {
            val?.adjustment?.map((val) => {
              promoCodesProdArr.push(val.code.split("-")[0]);
              promoAmountProdArr += val.amount?.substring(1);
            });
          }
          const unitPriceDiscounted = (
            parseFloat(val.unitPrice) - parseFloat(promoAmountProdArr)
          ).toString();
          return {
            SKU: val.partNumber,
            quantity: parseInt(val.quantity),
            unitPrice: parseFloat(val.unitPrice).toFixed(2), 
            name:getCartItems[index]?.name, 
            availability: "in-stock",
            category: "no-category",
            subCategory: "no-subcategory",
            nameplate: "no nameplate",
            modelYear: "no-my",
            unitPriceDiscounted: parseFloat(unitPriceDiscounted).toFixed(2),
            unitDiscountAmount: parseFloat(promoAmountProdArr).toFixed(2),
            totalInstallationCost: "0",
            totalCost: parseFloat(val.unitPrice).toFixed(2),
            installationType: "no-labor",
            discountCode:
              promoCodesProdArr.length > 0
                ? promoCodesProdArr.join()
                : "no-discount-code",
          };
        }),
      };
      digitaldata.event = {
        action: parameters?.hierarchy,
      };      
    } else if (parameters?.eventType === "removeCart") {
      digitaldata.onclick = {
        onclickLinkName: parameters?.onclickLinkName,
        onclick: parameters?.onClickName,
      };
      digitaldata.cart = {
        productInfo: {
          name: parameters?.productName,
          SKU: parameters?.partNumber,
          quantity: parseInt(parameters?.quantity),
          unitPrice: parseFloat(parameters?.unitPrice).toFixed(2),
          category: "no-category",
          subCategory: "no-subcategory",
          nameplate: "no nameplate",
          modelYear: "no-my",
          installationType: "no-labor",
          // availability: parameters?.availability,
          availability: "in-stock",
        },
        step: "scRemove",
      };
      digitaldata.event = {
        action: "cart:remove",
      };
    } else if (parameters?.eventType === "referralExit") {
      digitaldata.onclick = {
        onclickLinkName: parameters?.onClickLinkName,
        onclick: parameters?.onClickName,
        referredTo: parameters?.referredTo,
      };
      digitaldata.event = {
        action: "brand referral",
      };
    } else if (parameters?.eventType === "globalExit") {
      digitaldata.onclick = {
        onclickLinkName: parameters?.onClickLinkName,
        onclick: parameters?.onClickName,
      };
    } else if (parameters?.eventType === "addToCart") {
      digitaldata.cart = {
        step: "scAdd",
        productInfo: [
          {
            SKU: parameters.partnumber,
            quantity: parseInt(parameters.quantity),
            unitPrice: parameters?.unitPrice,
            name: parameters.name,
            category: "no-category",
            subCategory: "no-subcategory",
            nameplate: "no nameplate",
            modelYear: "no-my",
            installationType: "no-labor",
            availability: "in-stock",
          },
        ],
      };
      digitaldata.event = {
        action: "cart:add",
      };
      digitaldata.onclick = {
        onclickLinkName: "",
        onclick: "",
      };
    } else if (parameters?.eventType === "checkoutThankyou") {
      
      //FPR Amount calculation
      let fordRedeemPointsUsedVal = '';
      if (parameters?.FPR_Enabled) {
        const fordRedeemPointsUsedArray = parameters?.orderData?.adjustment?.length > 0 ? (parameters?.orderData?.adjustment.filter((f)=>f.code.includes("FPR"))):0;
        const fordRedeemPointsUsed = fordRedeemPointsUsedArray[0]?.amount.slice(1);
        fordRedeemPointsUsedVal = typeof(fordRedeemPointsUsed) === "undefined" ? 0 : fordRedeemPointsUsed;
      }

      const getCartItems = localStorageUtil.get('cartItems');
      
      digitaldata.cart = {
        step: "purchase",
        orderNumber: parameters?.orderData?.customerOrderNumber,
        subTotal: parseFloat(parameters?.orderData?.totalProductPrice).toFixed(
          2
        ),
        cartTotal: parseFloat(parameters?.orderData?.grandTotal).toFixed(2),
        fpRewards: fordRedeemPointsUsedVal,
        discountCode: (parameters?.orderData?.promotionCode && parameters?.orderData?.promotionCode?.length > 0) ? parameters?.orderData?.promotionCode?.map((discountVal: any) => ({ discountCode: discountVal?.code })): 'no-discount-code',
        discountAmount: parseFloat(
          parameters?.orderData?.totalAdjustment?.substring(1)
        ).toFixed(2),
        subTotalNoDiscount: parseFloat(
          parameters?.orderData?.totalProductPrice
        ).toFixed(2),
        shippingCost: parseFloat(
          parameters?.orderData?.totalShippingCharge
        ).toFixed(2),
        taxes: parseFloat(parameters?.orderData?.totalSalesTax).toFixed(2),
        deliveryMethod: "free shipping",
        productInfo: parameters?.orderData?.orderItem?.map(
          (val: any, i: any) => {
            var promoCodesProdArr: any = [];
            var promoAmountProdArr: any = 0;
            if (val?.adjustment?.length > 0) {
              val?.adjustment?.map((val) => {
                promoCodesProdArr.push(val.code.split("-")[0]);
                promoAmountProdArr += val.amount?.substring(1);
              });
            }

            const unitPriceDiscounted = (
              parseFloat(val.unitPrice) -
              parseFloat(promoAmountProdArr)
              // parseFloat(parameters?.orderData?.totalAdjustment?.substring(1))
            ).toString();
            return {
              SKU: val?.partNumber,
              quantity: parseInt(val?.quantity),
              unitPrice: parseFloat(val?.unitPrice).toFixed(2),
              name:getCartItems[i]?.name,
              
              category: "no-category",
              subCategory: "no-subcategory",
              nameplate: "no nameplate",
              modelYear: "no-my",
              installationType: "no-labor", 
              availability: "in-stock",
              unitPriceDiscounted: parseFloat(unitPriceDiscounted).toFixed(2),
              unitDiscountAmount: parseFloat(promoAmountProdArr).toFixed(2),
              totalInstallationCost: "0",
              totalCost: parseFloat(val.unitPrice).toFixed(2),
              discountCode:
                promoCodesProdArr.length > 0
                  ? promoCodesProdArr.join()
                  : "no-discount-code",
            };
          }
        ),
      };
      digitaldata.purchase = {
        purchaseID: parameters?.orderData?.orderId,
        transactionID: "",
      };
      digitaldata.event = {
        action: "cart:checkout:thank you",
      };
    } else if (parameters?.eventType === "productDetailPage") {
      digitaldata.cart = {
        step: "prodView",
        productInfo: {
          SKU: parameters.partnumber,
          unitPrice: parameters.product?.price[1]?.value,
          name: parameters.product.shortDescription,
          category: "no-category",
          subCategory: "no-subcategory",
          nameplate: "no nameplate",
          modelYear: "no-my",
          installationType: "no-labor",
          availability: parameters?.availability ? "in-stock" : "out-of-stock",
        },
      };
      digitaldata.event = {
        action: "product details",
      };
    } else if (parameters?.eventType === "Fmaloginsuccess") {
      digitaldata.onclick = {
        onclickLinkName: parameters?.onclickLinkName,
        onclick: parameters?.onclick,
      };
      digitaldata.user = {
        loginStatus: "logged in",
        registeredStatus: "registered",
      };
      digitaldata.page = {
        pageName: "fcg:" + parameters?.pageName,
        siteSection: parameters?.siteSection,
        hierarchy: parameters?.hierarchy,
        userLanguage: "eng",
        pagenameNoProduct: "fcg:" + parameters?.pagenameNoProduct,
        pageNameNoVehicle: "fma:login",
        referredTo: parameters?.referredTo,
        client: "ford",
        site: "chargers.ford.com",
        radUIVersion: "ui:rad:" + viewPort,
        variantName: parameters?.variantName,
        tool: "event:logins",
        siteSearchString: parameters?.siteSearchString,
        searchDepth: parameters?.searchDepth,
      };
    }
    return digitaldata;
  },

  callSatellite(result: any) {
    try {
      (window as any).digitaldata = result;
      (window as any)._satellite.track((window as any).digitaldata?.page?.variantName, result);
    } catch (e) {
      console.log("Could not connect to Adobe tags service", e);
    }
  },
 
};

export default adobeService;
